import React from 'react';
import Spline from '@splinetool/react-spline';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className={`App relative`} style={{ backgroundColor: 'black' }}>
      <div className="h-[100vh] md:h-[100vh] absolute z-0">
        <Spline
          scene="https://prod.spline.design/micZ2vW5ANXe0eFk/scene.splinecode"
          style={{ height: '100%', width: '100vw', opacity: '0.9' }}
        />
      </div>
      <div className="flex items-center justify-center flex-col text-[#fefefe] opacity-100 z-50 h-[100vh] pt-[2rem] lg:pt-[2rem]">
        <div
          className="z-50 p-8 rounded-3xl lg:w-[60vw] w-[95vw] bg-opacity-25 backdrop-blur-lg backdrop-filter drop-shadow-2xl flex flex-col gap-7 items-center"
          style={{
            boxShadow: '0px 0px 20px 9px black',
            backgroundPosition: 'center top',
            background: 'linear-gradient( rgb(255,255,255, 0.06), transparent)'
          }}
        >
          <div className="z-50 text-[32px] md:text-[45px] lg:text-[60px] font-medium  leading-[3rem] lg:leading-[4.5rem]">
            You're Now on the Waitlist
          </div>
          <div
            className="z-50 text-[16px] lg:text-[18px] text-center w-[90vw] md:w-[80vw] lg:w-[30vw] text-[#a2a9b6] "
            style={{ wordSpacing: '1.3px' }}
          >
            You will receive an access token soon.
          </div>
          <button
            className="m-[5px] bg-yellow-500 hover:bg-yellow-600 text-[12px]  text-black text-lg font-semibold py-4 px-4 rounded-3xl lg:rounded-full shadow-md"
            onClick={() => navigate('/')}
          >
            Go to Home Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
