import React, { useState } from 'react';
import Spline from '@splinetool/react-spline';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { FaMedium } from 'react-icons/fa6';
import { FaTelegram } from 'react-icons/fa';
import chat from './../assets/Frame 314.png';
import { IoIosCloseCircle } from 'react-icons/io';
import { axiosBaseInstance } from '../utils/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ENVIRONMENTS } from '../utils/environment';

const HomePage = () => {
  const [modal, setModal] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [accessCode, setAccessCode] = useState('');

  const navigate = useNavigate();

  const handleSubscription = async () => {
    try {
      if (!emailInput) {
        toast.error('Please enter your email');
        return;
      }

      const res = await axiosBaseInstance().post('/waitlist/join', {
        email: emailInput
      });

      if (res?.status === 200 || res?.status === 201) {
        navigate('/success');
        return;
      }

      toast.error(
        res?.data?.message || 'Something went wrong, try again later'
      );
      return;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          'Something went wrong, try again later'
      );
    }
  };

  const handleValidateCode = async () => {
    try {
      if (!accessCode) {
        toast.error('Please enter your access code');
        return;
      }

      const res = await axiosBaseInstance().post('/waitlist/validate', {
        code: accessCode
      });

      if (res?.status === 200 || res?.status === 201) {
        toast.success('Code is valid, redirecting .....');
        setTimeout(() => {
          console.log('redirecting .....');

          window.location.href = `${ENVIRONMENTS.APP.TYPE_AI_FRONTEND_URL}?accessCode=${accessCode}`;
        }, 1000);
        return;
      }

      toast.error(res?.data?.message || 'Code is invalid');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Code is invalid');
    }
  };

  return (
    <div className={`App relative`} style={{ backgroundColor: 'black' }}>
      {modal && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor: 'rgb(0,0,0, 0.5)',
            boxShadow: '0px 0px 20px 2px black',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="p-10 text-white bg-[#050505] shadow-lg lg:w-[30vw] relative">
            <div
              className="cursor-pointer"
              onClick={() => {
                setModal(false);
              }}
              style={{ position: 'absolute', right: 1, top: 1 }}
            >
              <IoIosCloseCircle color="#eab308" size={25} />
            </div>
            <div className="z-50 py-1 px-2 rounded-3xl lg:rounded-full bg-[#202020] flex items-center justify-between 2xl:w-[100%]">
              <input
                placeholder="Enter your access key"
                className="bg-inherit outline-none w-[100%]"
                value={accessCode}
                onChange={(e) => {
                  setAccessCode(e.target.value);
                }}
              />
              <button
                className="m-[5px] bg-yellow-500 hover:bg-yellow-600 text-[12px] w-[5.5rem] text-white font-semibold py-2 px-4 rounded-3xl lg:rounded-full shadow-md"
                onClick={handleValidateCode}
              >
                Enter
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="h-[100vh] md:h-[105vh] absolute z-0">
        <Spline
          scene="https://prod.spline.design/micZ2vW5ANXe0eFk/scene.splinecode"
          style={{ height: '100%', width: '100vw', opacity: '0.9' }}
        />
      </div>
      <div>
        <div className="flex items-center flex-col text-[#fefefe] opacity-100 z-50 h-[100vh] pt-[2rem] lg:pt-[2rem]">
          <div
            className="z-50 p-8 rounded-3xl lg:w-[60vw] w-[95vw] bg-opacity-25 backdrop-blur-lg backdrop-filter drop-shadow-2xl flex flex-col gap-7 items-center"
            style={{
              boxShadow: '0px 0px 20px 9px black',
              backgroundPosition: 'center top',
              background:
                'linear-gradient( rgb(255,255,255, 0.06), transparent)'
            }}
          >
            <div className="z-50 text-[32px] md:text-[45px] lg:text-[60px] font-medium  leading-[3rem] lg:leading-[4.5rem]">
              Your AI Crypto Companion
            </div>
            <div
              className="z-50 text-[16px] lg:text-[18px] text-center w-[90vw] md:w-[80vw] lg:w-[30vw] text-[#a2a9b6] "
              style={{ wordSpacing: '1.3px' }}
            >
              Join waitlist to get early access of Type-AI web
            </div>
            <div className="z-50 py-3 lg:px-5 px-3 rounded-3xl lg:rounded-full bg-[#202020] flex items-center justify-between 2xl:w-[50%]">
              <input
                placeholder="Email Address"
                className="bg-inherit outline-none"
                value={emailInput}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubscription();
                  }
                }}
              />
              <button
                className="m-[5px] bg-yellow-500 hover:bg-yellow-600 text-[12px] w-[5.5rem] text-white font-semibold py-2 px-4 rounded-3xl lg:rounded-full shadow-md"
                onClick={handleSubscription}
              >
                Join Beta
              </button>
            </div>
            <div
              style={{ zIndex: '999999' }}
              className="z-50 py-3 px-3 text-center font-bold 2xl:w-[50%]"
            >
              Already have an access key?{' '}
              <span
                onClick={() => {
                  setModal(true);
                }}
                style={{ zIndex: '999999' }}
                className="text-yellow-500 cursor-pointer"
                href=""
              >
                Click here
              </span>
            </div>
            <div className="z-50  sm:w-[28rem] ">
              <img src={chat} alt="" />
            </div>

            <div className="flex gap-4">
          <a
            className="cursor-pointer"
            href="https://twitter.com/Typeai2"
            target="_blank"
            rel="noreferrer"
          >
            <FaSquareXTwitter className="text-[#a2a9b6] text-[32px] hover:text-[#eab308] z-50 duration-200" />
          </a>
          <a
            className="cursor-pointer"
            href="https://medium.com/@typeai/the-inception-of-typeai-f9acad59585b"
            target="_blank"
            rel="noreferrer"
          >
            <FaMedium className="text-[#a2a9b6] text-[32px] hover:text-[#eab308] z-50 duration-200" />
          </a>

          <a
            className="cursor-pointer"
            href="https://t.me/typeaieth"
            target="_blank"
            rel="noreferrer"
          >
            <FaTelegram className="text-[#a2a9b6] text-[32px] hover:text-[#eab308] z-50 duration-200" />
          </a>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
