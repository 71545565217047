import axios from 'axios';
import { ENVIRONMENTS } from './environment';

export const axiosBaseInstance = (token) =>
  axios.create({
    baseURL: ` ${ENVIRONMENTS.APP.BASEURL}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
